import { Box, Button, Container, Image, Link } from "@chakra-ui/react";
import WLLogoWhite from "../../assets/logo/wl-logo-white.png";
import WLLogo from "../../assets/logo/wl-logo.png";
import { useEffect, useState } from "react";
import { IoMdCall } from "react-icons/io";

const Navbar = () => {
  const [isOnTop, setIsOnTop] = useState(true);

  useEffect(() => {
    const func = () => {
      setIsOnTop(window.scrollY === 0);
    }
    window.addEventListener("scroll", func);
    func();

    return () => window.removeEventListener("scroll", func);
  }, [])

  return <Box
    w="100%"
    py={4}
    zIndex={9}
    transition="all 0.1s ease-in-out"
    bgColor={isOnTop ? "transparent" : "white"}
    boxShadow={isOnTop ? "none" : "md"}
    pos={"fixed"}>
    <Container
      maxW={"container.xl"}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={'center'}>
      <Image
        h="60px"
        src={isOnTop ? WLLogoWhite : WLLogo} />
      <Button color={isOnTop ? "white" : "orange.500"} borderColor={isOnTop ? "white" : "orange.500"} borderRadius={"sm"} variant={"outline"} rightIcon={<IoMdCall />} borderWidth={"2px"} _hover={{
        bg: "orange.500",
        color: "#fff",
        borderColor: "orange.500"
      }}><Link href="tel:03412636264" _hover={{ textDecor: "none" }}>Call Us</Link></Button>
    </Container>
  </Box>
}

export default Navbar