import { Box } from '@chakra-ui/react';
import Navbar from './Navbar';

const Layout = ({
    children
}: {
    children: JSX.Element | JSX.Element[]
}) => {
    return <Box>
        <Navbar />
        {children}
    </Box>
}

export default Layout;