import { Box, Card, Container, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import Circles from "../../assets/BGs/circles.svg";

const MyCard = ({
    title,
    value
}: {
    title: string,
    value: string
}) => {
    return <Card borderRadius="sm" display="block" m="auto" w="100%" p={8} h="200px">
        <Text fontWeight={500}>{title}</Text>
        <Heading pos="absolute" bottom="8" fontWeight={600}>{value}</Heading>
    </Card>
}

const Cards = () => {
    return <Box
        py={10}
        minH="60vh"
        bgImage={Circles}
        bgRepeat={"no-repeat"}
        bgPosition={"center"}
        bgSize={"cover"}>
        <Box mt="-80px">
            <Container maxW={'container.xl'}>
                <SimpleGrid gap={5} minChildWidth={"250px"} justifyContent={"center"} alignItems={"center"}>
                    <MyCard title={"Available Spaces"} value={"50+"} />
                    <MyCard title={"High Speed Internet"} value={"20+ MBps"} />
                    <MyCard title={"Active Members"} value={"20+"} />
                    <MyCard title={"Positive Review"} value={"99%"} />
                </SimpleGrid>
            </Container>
        </Box>
        <Container mt="50px" maxW="container.lg" minH="40vh" display="flex" justifyContent={"center"} alignItems={"center"}>
            <Heading textAlign={"center"} letterSpacing={"widest"} fontWeight={500}>
                Discover the power of co-working and unlock your full potential in a dynamic, innovative, and vibrant community. Our state-of-the-art co-working space is designed to inspire creativity, foster connections, and fuel your entrepreneurial spirit.
            </Heading>
        </Container>
    </Box>
}

export default Cards