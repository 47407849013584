import { Box, Container, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { FaWifi, FaUsers, FaRegSnowflake, FaCoffee, FaUserAlt } from "react-icons/fa";
import { IoMdDesktop } from "react-icons/io";
import { MdKitchen } from "react-icons/md";

interface FeatureType {
    title: string;
    icon: IconType
}

const features: FeatureType[] = [{
    title: "High Speed Internet",
    icon: FaWifi
}, {
    title: "Meeting Rooms",
    icon: FaUsers
}, {
    title: "Equipped Kitchen",
    icon: MdKitchen
}, {
    title: "Fully Furnished",
    icon: IoMdDesktop
}, {
    title: "OnSite Staff",
    icon: FaUsers
}, {
    title: "Air Conditioned",
    icon: FaRegSnowflake
}, {
    title: "Refreshments",
    icon: FaCoffee
}, {
    title: "Reception",
    icon: FaUserAlt
}];

const Feature = ({ title, icon }: FeatureType) => {
    return <VStack m={5} bgColor={"#faf7f2"} p={5} textAlign={"center"} gap={2} borderRadius={"md"} boxShadow={"lg"}>
        <Box as={icon} fontSize={"3xl"} color={"orange.400"} />
        <Text fontWeight={600}>{title}</Text>
    </VStack>
}

const Features = () => {
    return <Box
        py={20}
        minH="60vh">
        <Container maxW="container.xl">
            <VStack maxW="container.md" display={"block"} m="auto" textAlign="center" gap={10} mb={10}>
                <Text color={"orange.400"} textTransform={"uppercase"} fontWeight={600} letterSpacing={"widest"}>facilities</Text>
                <Text color="black" fontSize={"4xl"} fontWeight={600}>Redefine your work experience</Text>
                <Text color="blackAlpha.700" fontWeight={500}>Discover a coworking space that empowers productivity, connection, and growth.</Text>
            </VStack>
            <SimpleGrid minChildWidth={"250px"} gap={5}>
                {features.map((feature, index) => <Feature key={index} {...feature} />)}
            </SimpleGrid>
        </Container>
    </Box>
}

export default Features