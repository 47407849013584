import Layout from "./components/Layout";
import Features from "./routes/Features";
import Home from "./routes/Home";

const App = () => {
  return <Layout>
    <Home />
    <Features />
  </Layout>
}

export default App