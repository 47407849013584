import Cards from "./Cards"
import Header from "./Header"

const Home = () => {
    return <>
        <Header />
        <Cards />
        
    </>
}

export default Home