import { Box, Button, Container, HStack, Heading, Icon, Link, Text, VStack } from "@chakra-ui/react";
import MeetingTableSide from "../../assets/images/meeting-table-side.jpg";
import WorkStationsSide from "../../assets/images/workstations-side.jpg";
import SmallMeetingSide from "../../assets/images/small-meeting-side.jpg";
import { useEffect, useState } from "react";
import { MdCall } from "react-icons/md";
import { BsArrowRightShort } from "react-icons/bs";
import { RiFacebookCircleLine } from "react-icons/ri";
import { FaInstagram, FaWhatsapp } from "react-icons/fa";

const Header = () => {
    const [bgImage, setBgImage] = useState(MeetingTableSide);

    useEffect(() => {
        const interval = setInterval(() => {
            const images = [MeetingTableSide, WorkStationsSide, SmallMeetingSide];
            const randomImage = images[Math.floor(Math.random() * images.length)];
            setBgImage(randomImage);
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    return <Box
        transition="background-image 1s ease-in-out"
        bgImage={bgImage}
        bgSize="cover"
        bgPosition="center"
        bgRepeat="no-repeat">
        <Box bgGradient={
            "linear(to-r, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))"
        }>
            <Container
                minH="90vh"
                pt={{
                    base: "150px",
                    md: "none",
                }}
                maxW="container.xl"
                h="100%"
                flexDir={{
                    base: "column",
                    md: "row",
                }}
                justifyContent="space-between"
                alignItems="center"
                display="flex"
                gap={5}>
                <Box w="70%" color='#fff' textAlign={{
                    base: "center",
                    md: "left",
                }}>
                    <Text
                        mb="20px"
                        textTransform={"uppercase"}
                        fontWeight={600}
                        letterSpacing={"wider"}>welcome to worker lounge</Text>
                    <Heading
                        fontSize={"clamp(2rem, 5vw, 3rem)"}
                        fontWeight={700}
                        letterSpacing={"wider"}>
                        Collaborate, Innovate, Elevate.
                    </Heading>
                    <Heading fontWeight={500} size={"lg"} mb="20px">
                        Your Gateway to Productivity and Success
                    </Heading>
                    <Text w={{
                        base: "100%",
                        md: "70%",
                    }}
                        m={{
                            base: "auto",
                            md: 0,
                        }} display={{
                            base: "block",
                            md: undefined,
                        }}
                    >
                        Welcome to a Thriving Community Where Ideas Spark, Connections Flourish, and Success is Amplified: Embrace the Synergy of Collaborative Innovation at our Premier Co-Working Space.
                    </Text>
                    <HStack mt="30px" gap={3} mx="auto" display="block">
                        <Button colorScheme="orange" borderRadius={"sm"} leftIcon={<MdCall />}><Link href="tel:03412636264" _hover={{ textDecor: "none" }}>Book Now!</Link></Button>
                        <Button borderRadius={"sm"} variant={"outline"} rightIcon={<BsArrowRightShort />} borderWidth={"2px"} _hover={{
                            bg: "orange.500",
                            color: "#fff",
                            borderColor: "orange.500"
                        }}><Link href="tel:03412636264" _hover={{ textDecor: "none" }}>Take a Tour</Link></Button>
                    </HStack>
                </Box>
                <VStack mt="auto" mb="100px" gap={4} flexDir={{
                    base: "row",
                    md: "column",
                }} alignItems={"center"}>
                    <Icon _hover={{
                        color: "orange.500"
                    }} transition="all 0.1s ease-in-out" cursor={"pointer"} color={"white"} fontSize="20px" as={FaWhatsapp} />
                    <Icon _hover={{
                        color: "orange.500"
                    }} transition="all 0.1s ease-in-out" cursor={"pointer"} color={"white"} fontSize="22px" as={RiFacebookCircleLine} />
                    <Icon _hover={{
                        color: "orange.500"
                    }} transition="all 0.1s ease-in-out" cursor={"pointer"} color={"white"} fontSize="20px" as={FaInstagram} />
                </VStack>
            </Container>
        </Box>
    </Box>
}

export default Header;